.app-user  .myApplications{
    margin: 80px 10px 20px 10px;
    border-radius: 0.375rem;
    min-height: 100vh;
}

.myApplications .nav-link{
    color: black;
    margin: 10px;
    background-color: white;
}

.myApplications .nav-link:hover{
    cursor: pointer;
}

.myApplications .nav-link.active{
    background-color: #a5702e;
    border: 0px;
    color: white;
}