:root{
	--form-height:550px;
	--form-width: 900px;
	/*  Sea Green */
	--left-color: darkorange;
	/*  Light Blue  */
	--right-color: darkorange;
  }

  .signup-email-notification {
	color: white;
	margin-top: 100px;
	margin-bottom: 30px;
	text-align: center;
  }
  
  .login.container{
	width: var(--form-width);
	height: var(--form-height);
	margin: auto;
	box-shadow: 2px 10px 40px rgba(22,20,19,0.4);
	border-radius: 10px;
	position: relative;
	margin-top: 100px;
	margin-bottom: 100px;
	background: white;
	padding-left: 0px;
	/* top: 0;
	bottom: 0;
	left: 0;
	right: 0; */
  }

  .login.container.emailSent{
	margin-top: 0px;
  }

  /* 
  ----------------------
		Overlay
  ----------------------
  */
  .login .overlay{
	width: 100%; 
	height: 100%;
	position: absolute;
	z-index: 100;
	background-image: linear-gradient(to right, var(--left-color), var(--right-color));
	border-radius: 10px;
	color: white;
	clip: rect(0, 385px, var(--form-height), 0);
  }
  
  .login .open-sign-up{
	  animation: slideleft 1s linear forwards;
  }
  
  .login .open-sign-in{
	  animation: slideright 1s linear forwards;
  }

  .login .open-sign-in-initial{
	animation: slideright 0s linear forwards;
}
  
  .login .overlay .sign-in, .login .overlay .sign-up{
	/*  Width is 385px - padding  */
	--padding: 20px;
	width: calc(385px - var(--padding) * 2);
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0px var(--padding);
	text-align: center;
  }
  
  .login .overlay .sign-in{
	float: left;
  }
  
  .login .overlay-text-left-animation{
	  animation: text-slide-in-left 1s linear;
  }
  .login .overlay-text-left-animation-out{
	  animation: text-slide-out-left 1s linear;
  }
  
  .login .overlay .sign-up{
	float:right;
  }
  
  .login .overlay-text-right-animation{
	  animation: text-slide-in-right 1s linear;
  }
  
  .login .overlay-text-right-animation-out{
	  animation: text-slide-out-right 1s linear;
  }
  
  
  .login .overlay h1{
	margin: 0px 5px;
	font-size: 2.1rem;
  }
  
  .login .overlay p{
	margin: 20px 0px 30px;
	font-weight: 200;
  }
  /* 
  ------------------------
		Buttons
  ------------------------
  */
  .login .switch-button, .login .control-button{
	cursor: pointer;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 140px;
	height: 40px;
	font-size: 14px;
	text-transform: uppercase;
	background: none;
	border-radius: 20px;
	color: white;
  }
  
  .login .switch-button{
	border: 2px solid;
  }
  
  .login .control-button{
	border: none;
	margin-top: 15px;
  }
  
  .login .switch-button:focus, .login .control-button:focus{
	outline:none;
  }
  
  .login .control-button.up{
	background-color: var(--left-color);
  }
  
  .login .control-button.in{
	background-color: var(--right-color);
  }
  
  /* 
  --------------------------
		Forms
  --------------------------
  */
  .login .form{
	width: 100%; 
	height: 100%;
	position: absolute;
	border-radius: 10px;
  }
  
  .login .form .sign-in, .login .form .sign-up{
	--padding: 0px;
	position:absolute;
	  /*  Width is 100% - 385px - padding  */
	width: calc(var(--form-width) - 385px - var(--padding) * 2);
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0px var(--padding);
	text-align: center;
  }

  .sign-in.form-left-slide-in{
	--padding: 50px!important;
  }
  
  /* Sign up is initially not displayed */
  .login .form .sign-up{ 
	display: none;
  }
  
  .login .form .sign-in{
	left:0;
  }
  
  .login .form .sign-up{
	right: 0;
  }
  
  .login .form-right-slide-in{
	animation: form-slide-in-right 1s;
  }
  
  .login .form-right-slide-out{
	animation: form-slide-out-right 1s;
  }
  
  .login .form-left-slide-in{
	animation: form-slide-in-left 1s;
  }
  
  .login .form-left-slide-out{
	animation: form-slide-out-left 1s;
  }
  
  .login .form .sign-in h1{
	color: var(--right-color);
	margin: 0;
  }
  
  .login .form .sign-up h1{
	color: var(--left-color);
	margin: 0;
  }
  
  .login .social-media-buttons{
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 15px;
  }
  
  .login .social-media-buttons .icon{
	width: 40px;
	height: 40px;
	border: 1px solid #dadada;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 7px;
	cursor: pointer;
  }
  
  .login .small{
	font-size: 13px;
	color: grey;
	font-weight: 200;
	margin: 5px;
  }
  
  .login .social-media-buttons .icon svg{
	width: 25px;
	height: 25px;
  }
  
  .login #sign-in-form input, #sign-up-form input{
	margin: 12px;
	font-size: 14px;
	padding: 15px;
	width: 260px;
	font-weight: 300;
	border: none;
	background-color: #e4e4e494;
	font-family: 'Helvetica Neue', sans-serif;
	letter-spacing: 1.5px;
	padding-left: 20px;
  }
  
  .login #sign-in-form input::placeholder{
	letter-spacing: 1px;
  }
  
  .login .forgot-password{
	font-size: 12px;
	display: inline-block;
	border-bottom: 2px solid #efebeb;
	padding-bottom: 3px;
  }
  
  .login .forgot-password:hover{
	cursor: pointer;
  }

  .login .resendEmail {
	color: blue;
	cursor: pointer;
  }
  
  /* 
  ---------------------------
	  Animation
  ---------------------------
  */
  @keyframes slideright{
	0%{
	  clip: rect(0, 385px, var(--form-height), 0);
	}
	30%{
		  clip: rect(0, 480px, var(--form-height), 0);
	}
	/*  we want the width to be slightly larger here  */
	50%{
	   clip: rect(0px, calc(var(--form-width) / 2 + 480px / 2), var(--form-height), calc(var(--form-width) / 2 - 480px / 2));
	}
	80%{
		   clip: rect(0px, var(--form-width), var(--form-height), calc(var(--form-width) - 480px));
	}
	100%{
	   clip: rect(0px, var(--form-width), var(--form-height), calc(var(--form-width) - 385px));
	}
  }
  
  @keyframes slideleft{
	100%{
	  clip: rect(0, 385px, var(--form-height), 0);
	}
	70%{
		  clip: rect(0, 480px, var(--form-height), 0);
	}
	/*  we want the width to be slightly larger here  */
	50%{
	   clip: rect(0px, calc(var(--form-width) / 2 + 480px / 2), var(--form-height), calc(var(--form-width) / 2 - 480px / 2));
	}
	30%{
		   clip: rect(0px, var(--form-width), var(--form-height), calc(var(--form-width) - 480px));
	}
	0%{
	   clip: rect(0px, var(--form-width), var(--form-height), calc(var(--form-width) - 385px));
	}
  }
  
  @keyframes text-slide-in-left{
	0% {
	  padding-left: 20px;
	}
	100% {
	  padding-left: 50px;
	}
  }
  
  @keyframes text-slide-in-right{
	0% {
	  padding-right: 20px;
	}
	100% {
	  padding-right: 50px;
	}
  }
  
  @keyframes text-slide-out-left{
	0% {
	  padding-left: 50px;
	}
	100% {
	  padding-left: 20px;
	}
  }
  
  @keyframes text-slide-out-right{
	0% {
	  padding-right: 50px;
	}
	100% {
	  padding-right: 20px;
	}
  }
  
  @keyframes form-slide-in-right{
	0%{
	  padding-right: 100px;
	}
	100%{
	  padding-right: 50px;
	}
  }
  
  @keyframes form-slide-in-left{
	0%{
	  padding-left: 100px;
	}
	100%{
	  padding-left: 50px;
	}
  }
  
  @keyframes form-slide-out-right{
	0%{
	  padding-right: 50px;
	}
	100%{
	  padding-right: 80px;
	}
  }
  
  @keyframes form-slide-out-left{
	0%{
	  padding-left: 50px;
	}
	100%{
	  padding-left: 80px;
	}
  }

  .password-input{
	position: relative;
  }

  .password-input .password-eye-icon{
	position: absolute;
	right: 0px;
	padding-top: 25px;
	display: inline-block;
	cursor: pointer
  }