.news {
  margin-top: 40px;
}

.news .ticker {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6e6152;
}

.news .title {
  color: white;
  background: #d07d1e;
  margin: 0px;
  padding: 10px;
  white-space: nowrap
}

.news .news-content{
    display: contents;
    color: white;
}