.shadow {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.profile-tab-nav {
	min-width: 250px;
}

.tab-content {
	flex: 1;
}

.form-group {
	margin-bottom: 1.5rem;
}

.nav-pills div.nav-link {
	padding: 15px 20px;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	color: #333;
    cursor: pointer;
}
.nav-pills div.nav-link i {
	width: 20px;
}

.img-circle img {
	height: 100px;
	width: 100px;
	border-radius: 100%;
	border: 5px solid #fff;
}

.nav-pills div.nav-link.active {
    background-color: #a5702e;
}

#v-pills-tabContent{
    background-color: #ff92009e;
}

.myProfile section {
    padding: 40px 0;
}

.mr-1, .mx-1 {
    margin-right: 0.5rem!important;
}

.nav-pills .nav-link i {
    font-size: 18px;
    color: black;
}

.nav-pills .nav-link.active i {
    color: white;
}

.iti {
    position: relative;
    display: revert!important;
}

button.editProfile {
	background-color: rgb(102 56 0);
	border: none;
	color: white;
}

button.editProfile:hover {
	background-color: rgb(102 56 0);
	border: none;
	color: white;
}

.myProfile .updateBtn button.updateCancel{
	margin: 20px;
	margin-left: auto;
	margin-top: auto;
}

.myProfile .updateBtn button.update{
	background-color: rgb(102 56 0);
	border: none;
}

.myProfile .form-control:disabled{
	cursor: not-allowed;
}

/* .myProfile #mobile_code{
	width: 40%;
} */

.myProfile .phone{
	display: flex;
}

.myProfile .phone i{
	position: relative;
	left: 15px;
	font-size: 24px;
}

.myProfile .phone i.success{color: green;}
.myProfile .phone i.fail{color: red;}

.myProfile #v-pills-tabContent {
	overflow: auto;
}

.myProfile .accordion{
	background-color: transparent;
}

.myProfile .accordion-item{
	margin: 20px;
	border: none;
	margin-bottom: 20px;
	
}

.myProfile .accordion-button{
	background: #a5702e;
	color: white;
	outline: none;
	box-shadow: none;
}

.myProfile .accordion-button::after{
	background-image: none;
	content: "\f282";
    font-family: "bootstrap-icons";
}

.myProfile #fileInputImg, .myProfile #fileInputResume, .myProfile #fileInputCoverLetter {
	display: none;
}

.myProfile label.uploadResume{
	cursor: pointer;
	background-color: #d07d1e;
	color: white;
	outline: none;
	box-shadow: none;
}

.myProfile label.uploadResume:hover{
	background-color: #d07d1e;
	color: white;
}

.tableRowFile th, .tableRowFile .fileName{
	vertical-align: middle;
}


@media (min-width: 768px) {
	.myProfile .container-fluid .bg-white.shadow{
		height: 600px;
	}

	.myProfile .userProfileName {
		max-width: 200px;
	}
}

@media (max-width: 767px) {
	#v-pills-tabContent{
		height: 60vh;
	}
}

.content_img img:hover{
	cursor: pointer;
}

.content_img .middle{
	margin-top: 10px;
}