.allow-dropdown.intl-tel-input{
    width: 100%;
}

input[type="tel"] {
    height: 40px;
    width: 100%;
    border: transparent;
}

input[type="tel"]:focus {
    outline: none;
}

input[type="text"], input[type="email"], input[type="select"], input[type="tel"] {
    border-radius: 0.375rem!important;
}

input[type="file"] {
    font-size: unset!important;
}

.myApplications .submittedApplication{
    margin-top: 100px;
    color: white;
}

.myApplications .btn-started {
    background: #d07d1e;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: white;
    margin-left: 10px;
}

.myApplications form label {
    display: block;
}

.myApplications  .phone{
	display: flex;
}

.myApplications .phone i{
	position: relative;
	right: 30px;
    top: 5px;
	font-size: 20px;
}

.myApplications  .phone i.success{color: green;}
.myApplications  .phone i.fail{color: red;}

.disabledInput {
    background-color: #00000042!important;
}

.myApplications input[type="date"] {
    display: inline-block;
    position: relative;
}

.myApplications input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.hideSubmitBtn {
    display: none;
}

.myApplications .registerForm input:disabled, .myApplications .registerForm input:read-only {
    background: #e9ecef;
} 

.myApplications .form-control {
    font-size: 1rem!important;
}