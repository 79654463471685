.app-management-login a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.app-management-login a:focus {
  outline: none !important;
}

.app-management-login a:hover {
  text-decoration: none;
  color: #57b846;
}

/*---------------------------------------------*/
.app-management-login input {
  outline: none;
  border: none;
}

.app-management-login input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  -webkit-appearance: none;
}

.app-management-login input[type="number"]::-webkit-outer-spin-button,
.app-management-login input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.app-management-login textarea {
  outline: none;
  border: none;
}

.app-management-login textarea:focus,
.app-management-login input:focus {
  border-color: transparent !important;
}

.app-management-login input:focus::-webkit-input-placeholder {
  color: transparent;
}

.app-management-login input:focus:-moz-placeholder {
  color: transparent;
}

.app-management-login input:focus::-moz-placeholder {
  color: transparent;
}

.app-management-login input:focus:-ms-input-placeholder {
  color: transparent;
}

.app-management-login textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.app-management-login textarea:focus:-moz-placeholder {
  color: transparent;
}

.app-management-login textarea:focus::-moz-placeholder {
  color: transparent;
}

.app-management-login textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.app-management-login input::-webkit-input-placeholder {
  color: #999999;
}

.app-management-login input:-moz-placeholder {
  color: #999999;
}

.app-management-login input::-moz-placeholder {
  color: #999999;
}

.app-management-login input:-ms-input-placeholder {
  color: #999999;
}

.app-management-login textarea::-webkit-input-placeholder {
  color: #999999;
}

.app-management-login textarea:-moz-placeholder {
  color: #999999;
}

.app-management-login textarea::-moz-placeholder {
  color: #999999;
}

.app-management-login textarea:-ms-input-placeholder {
  color: #999999;
}

.app-management-login label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
.app-management-login button {
  outline: none !important;
  border: none;
  background: transparent;
}

.app-management-login button:hover {
  cursor: pointer;
}

.app-management-login iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
.app-management-login .txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}

/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.app-management-login .limiter {
  width: 100%;
  margin: 0 auto;
}

.app-management-login .container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  /* background: #ebeeef; */
}

.app-management-login .wrap-login100 {
  width: 670px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.app-management-login .login100-form-title {
  width: 100%;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 15px 74px 15px;
}

.app-management-login .login100-form-title-1 {
  font-family: Poppins-Bold;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
}

.app-management-login .login100-form-title::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #5c380d;
}

.app-management-login .login100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 43px 88px 93px 190px;
}

.app-management-login .wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #b2b2b2;
}

.app-management-login .label-input100 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  text-align: right;
  position: absolute;
  top: 14px;
  left: -105px;
  width: 80px;
}

.app-management-login .input100 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  background: transparent;
  padding: 0 5px;
}

.app-management-login .focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.app-management-login .focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
  background: darkorange;
}

.app-management-login input.input100 {
  height: 45px;
}

.app-management-login .input100:focus + .focus-input100::before {
  width: 100%;
}

.app-management-login .has-val.input100 + .focus-input100::before {
  width: 100%;
}

.app-management-login .input-checkbox100 {
  display: none;
}

.app-management-login .label-checkbox100 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.app-management-login .label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #e6e6e6;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.app-management-login .input-checkbox100:checked + .label-checkbox100::before {
  color: #57b846;
}

.app-management-login .container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  /* display: flex;
  flex-wrap: wrap; */
}

.app-management-login .login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #5c380d;
  border-radius: 25px;
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.app-management-login .login100-form-btn:hover {
  background-color: #333333;
}

@media (max-width: 576px) {
  .app-management-login .login100-form {
    padding: 43px 15px 57px 117px;
  }
}

@media (max-width: 1024px) {
  .container-login100 {
    padding-top: 60px !important;
  }
}

@media (max-width: 480px) {
  .app-management-login .login100-form {
    padding: 43px 15px 57px 15px;
  }
  .app-management-login .label-input100 {
    text-align: left;
    position: unset;
    top: unset;
    left: unset;
    width: 100%;
    padding: 0 5px;
  }
}

.app-management-login .validate-input {
  position: relative;
}

.app-management-login .alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;
  font-family: Poppins-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.app-management-login .alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.app-management-login .alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .app-management-login .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.app-management-login .password-input {
  position: relative;
}

.app-management-login .password-input .password-eye-icon {
  position: absolute;
  right: 50px;
  top: 0px;
  display: inline-block;
  cursor: pointer;
}

@media (min-width: 481px) {
  .app-management-login .password-input .password-eye-icon {
    padding-top: 10px;
  }
}

.app-admin .phone {
  display: flex;
}

.app-admin .createEmployee .phone i {
  position: relative;
  left: 15px;
  font-size: 24px;
}

.app-admin .createEmployee .phone i.success {
  color: green;
}
.app-admin .createEmployee .phone i.fail {
  color: red;
}

@media (max-width: 426px) {
  .app-admin .createEmployee .phone i {
    left: 0px;
  }
}
