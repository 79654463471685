.myApplications .viewApplications .questionGroup {
  margin-bottom: 30px;
}

.app-employee .single-form, .app-admin .single-form {
  margin-bottom: 20px;
}

.app-employee .viewApplications, .app-admin .viewApplications {
  margin-top: 90px;
  margin-bottom: 50px;
}

.single-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 11px;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
}

.single-form input {
  border: none;
  border-bottom: 2px solid #d9d9d9;
  font-size: 18px;
  padding: 10px 0;
  width: 100%;
  background: transparent;
  color: white;
  height: 40px;
}

.single-form input:focus {
  outline: none;
}

.single-form .allow-dropdown.intl-tel-input {
  display: block;
  margin-bottom: 30px;
}

.single-form .viewFile {
  cursor: pointer;
  background-color: #d07d1e;
  color: white;
  outline: none;
  box-shadow: none;
}

.app-employee .accordion, .app-admin .accordion {
  background-color: transparent;
}

.app-employee .accordion-item, .app-admin .accordion-item {
  margin: 20px;
  border: none;
  margin-bottom: 20px;
}

.app-employee .accordion-button, .app-admin .accordion-button {
  background: #a5702e;
  color: white;
  outline: none;
  box-shadow: none;
}

.app-employee .accordion-button::after, .app-admin .accordion-button::after {
  background-image: none;
  content: "\f282";
  font-family: "bootstrap-icons";
}

.app-employee #fileInputImg, .app-employee #fileInputResume, .app-employee #fileInputCoverLetter,
.app-admin #fileInputImg, .app-admin #fileInputResume, .app-admin #fileInputCoverLetter {
	display: none;
}

.app-employee label.uploadResume, .app-admin label.uploadResume{
	cursor: pointer;
	background-color: #d07d1e;
	color: white;
	outline: none;
	box-shadow: none;
}

.app-employee label.uploadResume:hover, .app-admin label.uploadResume:hover{
	background-color: #d07d1e;
	color: white;
} 

.app-employee .updateBtn button.updateCancel, .app-admin .updateBtn button.updateCancel{
	margin: 20px;
	margin-left: auto;
	margin-top: auto;
}

.app-employee .updateBtn button.update, .app-admin .updateBtn button.update{
	background-color: rgb(102 56 0);
	border: none;
}

.viewEmployeeApplications::-webkit-scrollbar {
  background-color: black;
  width: 0.5em;
}

.viewEmployeeApplications::-webkit-scrollbar-thumb {
  background-color: white;
}