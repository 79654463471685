.myApplications {
  /* margin-top: 70px; */
}

.myApplications .wrapper {
  width: 100%;
}

.myApplications #sidebar {
  min-width: 270px;
  max-width: 270px;
  background: #663800;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: sticky;
}
.myApplications #sidebar .h6 {
  color: #fff;
}
.myApplications #sidebar.active {
  margin-left: -270px;
}
.myApplications #sidebar h1 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
}
.myApplications #sidebar h1 .logo {
  color: #fff;
}
.myApplications #sidebar h1 .logo span {
  font-size: 14px;
  color: #44bef1;
  display: block;
}
.myApplications #sidebar ul.components {
  padding: 0;
}
.myApplications #sidebar ul li {
  font-size: 16px;
}
.myApplications #sidebar ul li > ul {
  margin-left: 10px;
}
.myApplications #sidebar ul li > ul li {
  font-size: 14px;
}
.myApplications #sidebar ul li a {
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.myApplications #sidebar ul li a span {
  color: #44bef1;
}
.myApplications #sidebar ul li a:hover {
  color: #fff;
}
.myApplications #sidebar ul li.active > a {
  background: transparent;
  color: #fff;
}
@media (max-width: 991.98px) {
  .myApplications #sidebar {
    margin-left: -270px;
  }
  .myApplications #sidebar.active {
    margin-left: 0;
  }
}
.myApplications #sidebar .custom-menu {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .myApplications #sidebar .custom-menu {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.myApplications #sidebar .custom-menu .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}
.myApplications #sidebar .custom-menu .btn i {
  margin-right: -40px;
  font-size: 14px;
}
.myApplications #sidebar .custom-menu .btn.btn-primary {
  background: transparent;
  border-color: transparent;
}
.myApplications #sidebar .custom-menu .btn.btn-primary:after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #663800;
  border-radius: 10px;
}
.myApplications #sidebar .custom-menu .btn.btn-primary:hover,
.myApplications #sidebar .custom-menu .btn.btn-primary:focus {
  background: transparent !important;
  border-color: transparent !important;
}

.myApplications a[data-toggle="collapse"] {
  position: relative;
}

@media (max-width: 991.98px) {
  .myApplications #sidebarCollapse span {
    display: none;
  }

  .myApplications {
    margin-top: 61px;
  }
}

.myApplications .content {
  width: 100%;
  padding: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
}

.myApplications .footer p {
  color: rgba(255, 255, 255, 0.5);
}

.myApplications .form-control {
  height: 40px !important;
  background: #fff;
  color: #000;
  font-size: 13px;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: transparent;
}
.myApplications .form-control:focus,
.myApplications .form-control:active {
  border-color: #000;
}
.myApplications .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
}
.myApplications .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}
.myApplications .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
}
.myApplications .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}

.myApplications .subscribe-form .form-control {
  background: #3b4ec6;
}

.myApplications .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.myApplications .sr-only-focusable:active,
.myApplications .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.myApplications .content.active {
  margin-left: 0px;
}

@media (max-width: 991.98px) {
  .myApplications .content {
    margin-left: 0px;
  }
  .myApplications .content.active {
    margin-left: 270px;
  }
}

.myApplications .scrollBar {
  overflow-y: auto;
  height: 100vh;
}

.myApplications .main.dropdown {
  display: flex;
  justify-content: end;
}

.myApplications .btn-white.dropdown-toggle,
.myApplications .btn.view-application {
  background: #663800;
  color: white;
  border: none;
}

.myApplications .btn-white.dropdown-toggle::after {
  margin-left: 0.855em;
}

.myApplications .sortBy {
  position: relative;
  top: 5px;
  right: 10px;
  font-size: 18px;
  font-weight: 600;
  color: black;
  white-space: nowrap;
}

.myApplications .application {
  margin-bottom: 20px;
}

.myApplications .applicationId-key {
  color: black;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  white-space: nowrap;
}

.myApplications .applicationId-value {
  color: black;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.myApplications table td a.viewApplication {
  color: #3b4ec6;
  cursor: pointer;
}

.myApplications table th {
  white-space: nowrap;
  text-align: center;
}

.myApplications table tr {
  text-align: center;
}

.myApplications .filterQuestions input[type="search"] {
  height: 35px;
}

.myApplications .filterQuestions .clearBtn {
  height: 35px;
  /* background-color: whitesmoke; */
  color: white;
}

/* .myApplications .filterQuestions .clearBtn:hover{
  color: black;
} */
